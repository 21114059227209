import useTranslation from 'next-translate/useTranslation';

import { Box, InputNumberControl } from '@core/component';
import { Translate } from '@core/constant';
import { StockAlertForm } from '@core/type';

import { LineModalAlertField } from '../interface-madal-alert';

export const LineStockAlertField = ({ control, errors }: LineModalAlertField<StockAlertForm>) => {
  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      height="100%"
      marginBottom="16px"
    >
      <InputNumberControl
        control={control}
        name="quantity"
        nbDecimal={0}
        required
        label={tProduct('modals.addPriceAlert.pricePlaceholder')}
        isError={!!errors['quantity']}
      />
    </Box>
  );
};
