import { LinkProps } from 'next/link';
import { forwardRef } from 'react';

import { useContextRouting, useContextUtil } from '@core/context';
import { useLanguage } from '@core/hook';
import { ELanguage, ERouting } from '@core/type';
import { getLanguageFromTags, getLanguageTags, getLinkNext } from '@core/util';

import { StyledTranslatedLink } from './StyledLinkNext';
import { LinkTranslatedProps } from './interface-link-translated';

export const LinkTranslated = forwardRef<HTMLAnchorElement, LinkTranslatedProps>(
  (
    {
      keyRoute,
      children,
      language,
      disableScroll,
      disableShallow,
      enableAnimation,
      query = null,
      href: hrefProp,
      ...restProps
    }: LinkTranslatedProps,
    ref,
  ) => {
    const { pathTranslations, defaultRoute } = useContextRouting();
    const { isBotDetected } = useContextUtil();
    const { locale: currentLanguage } = useLanguage();

    const languageLink = language ? getLanguageFromTags(language) : currentLanguage;

    if (!keyRoute && !hrefProp) {
      return <>{children}</>;
    }

    let link: LinkProps = getLinkNext(
      keyRoute,
      getLanguageTags(languageLink),
      pathTranslations,
      defaultRoute,
      query,
    );

    if (isBotDetected) {
      link = {
        ...link,
        prefetch: false,
      };
    }

    const { as: forwardedAs, href, ...restLink } = link;

    return (
      <StyledTranslatedLink
        {...restLink}
        href={hrefProp || (href as string)}
        scroll={!disableScroll}
        shallow={!disableShallow}
        forwardedAs={forwardedAs}
        locale={
          keyRoute === ERouting.HOME &&
          (languageLink === ELanguage.EN || languageLink === ELanguage.DEFAULT)
            ? ELanguage.DEFAULT
            : languageLink === ELanguage.DEFAULT
              ? ELanguage.EN
              : languageLink
        }
        ref={ref}
        {...restProps}
      >
        {children}
      </StyledTranslatedLink>
    );
  },
);

LinkTranslated.displayName = 'LinkTranslated';
